import axios from '@axios'

export default {
    namespaced: true, state: {}, getters: {}, mutations: {}, actions: {

        fetchDriverList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-driver/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchDriverVehicleList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-driver/vehicle/list`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addDriverVehicle(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/admin-company/v1/app/user-driver/vehicle`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        delDriverVehicle(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`/admin-company/v1/app/user-driver/vehicle/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchVehicleList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-vehicle/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchVehicleDriverList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-vehicle/driver/list`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        addVehicleDriver(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/admin-company/v1/app/user-vehicle/driver`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        delVehicleDriver(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.delete(`/admin-company/v1/app/user-vehicle/driver/${id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchRealAuthList(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-driver/real-auth/${queryParams.options.page}/${queryParams.options.itemsPerPage}`, { params: queryParams }).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchRealAuthAccept(ctx, item) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/app/user-driver/real-auth/accept/${item.id}`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        fetchRealAuthRefuse(ctx, item) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/app/user-driver/real-auth/refuse/${item.id}`, { reason: item.failed_reason }).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchDriverEdit(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/app/user-driver/${data.id}`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchVehicleEdit(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.put(`/admin-company/v1/app/user-vehicle/${data.id}`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },

        fetchCompanyList(ctx) {
            return new Promise((resolve, reject) => {
                axios.get(`/admin-company/v1/app/user-company/1/999`).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        bindCompany(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/admin-company/v1/app/user-driver/bind-company`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
        bindCompanyForVehicle(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post(`/admin-company/v1/app/user-vehicle/bind-company`, data).then(response => resolve(response)).catch(error => reject(error))
            })
        },
    },
}
