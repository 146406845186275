import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useVehicleList() {
    const headers = [
        { text: '车牌号', value: 'vehicle_no' },
        { text: '手机号', value: 'mobile' },
        { text: 'imei', value: 'imei' },
        { text: '关联司机', value: 'driver_cnt' },
        { text: '保险到期', value: 'insure_end_at' },
        { text: '验车到期', value: 'verify_end_at' },
        { text: '挂靠单位', value: 'company_name' },
        { text: '状态', value: 'state' },
        { text: '备注', value: 'desc' },
        { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        key: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: { open: false, msg: '' },
        lodading: false,
        dialogEdit: {
            open: false,
            loading: false,
            content: {
                "id": "313bb63eaef840a9acffde7acb4d0a31",
                "vehicle_no": "浙FD9228",
                "imei": "868120241634994",
                "driver_id": "",
                "driver_name": "",
                "mobile": "18757350519",
                "insure_end_at": "0001-01-01",
                "verify_end_at": "0001-01-01",
                "company_id": "a06e511ada314d5c8f87d901dcbb54ff",
                "company_name": "海盐县东海汽车",
                "driver_cnt": 9,
                "state": "1",
                "desc": "",
                "create_at": "2023-09-09 17:06:13"
            }
        },
        dialogVehicle: {
            open: false,
            loading: false,
            item: {},
            list: [],
            dialogAdd: {
                open: false,
                loading: false,
                content: {}
            },
            dialogDel: {
                open: false,
                loading: false,
                content: {},
            }
        },
        dialogCompany: {
            open: false,
            loading: false,
            item: {},
            content: { id: '', company_id: '', auto_bind_vehicle: false },
            list: [],
        }
    })
    const enums = {
        status: { "1": "正常", "2": "禁用" },
        statusColor: { "1": "success", "2": "error" },
    }
    const fetchVehicleList = () => {
        table.value.loading = true
        store.dispatch('app-user-vehicle-list/fetchVehicleList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
        }).catch(error => {
            console.log(error)
        }).finally(table.value.loading = false)
    }

    const fetchDriverVehicleList = (item) => {
        table.value.dialogVehicle.loading = true
        table.value.dialogVehicle.item = { ...item }
        store.dispatch('app-user-vehicle-list/fetchVehicleDriverList', { id: item.id }).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogVehicle.list = data || []
            table.value.dialogVehicle.open = true
        }).catch(error => { console.log(error) }).finally(table.value.dialogVehicle.loading = false)
    }
    const toAddRelation = () => {
        table.value.dialogVehicle.dialogAdd = {
            open: true,
            loading: false,
            content: { driver_id: '' }
        }
    }
    const toEdit = (item) => {
        const content = { ...item }
        if (content.insure_end_at == "0001-01-01") {
            content.insure_end_at = "2024-01-01"
        }
        if (content.verify_end_at == "0001-01-01") {
            content.verify_end_at = "2024-01-01"
        }
        table.value.dialogEdit = {
            open: true,
            loading: false,
            content: content
        }
    }
    const addDriverVehicle = () => {
        const data = {
            vehicle_id: table.value.dialogVehicle.item.id,
            driver_id: table.value.dialogVehicle.dialogAdd.content.driver_id
        }
        store.dispatch('app-user-vehicle-list/addVehicleDriver', data).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogVehicle.dialogAdd.open = false
            fetchDriverVehicleList(table.value.dialogVehicle.item)
            fetchVehicleList()
        }).catch(error => { console.log(error) }).finally(table.value.dialogVehicle.dialogAdd.loading = false)
    }
    const toDelRelation = (content) => {
        table.value.dialogVehicle.dialogDel = {
            open: true,
            loading: false,
            content: content
        }
    }
    const delDriverVehicle = () => {
        store.dispatch('app-user-vehicle-list/delVehicleDriver', table.value.dialogVehicle.dialogDel.content.id).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogVehicle.dialogDel.open = false
            fetchDriverVehicleList(table.value.dialogVehicle.item)
            fetchVehicleList()
        }).catch(error => { console.log(error) }).finally(table.value.dialogVehicle.dialogDel.loading = false)
    }

    const toBindCompany = (item) => {
        table.value.dialogCompany.loading = true
        store.dispatch('app-user-vehicle-list/fetchCompanyList', table.value.dialogVehicle.dialogDel.content.id).then(res => {
            const { code, data, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            const { list } = data
            table.value.dialogCompany = {
                open: true,
                loading: false,
                item: item,
                content: { id: item.id, company_id: item.company_id, auto_bind_vehicle: false },
                list: list || [],
            }
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally()
    }
    const bindCompany = () => {
        const data = { ...table.value.dialogCompany.content }
        store.dispatch('app-user-vehicle-list/bindCompanyForVehicle', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogCompany.open = false
            fetchVehicleList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.dialogCompany.loading = false)
    }

    const doEdit = () => {
        const data = { ...table.value.dialogEdit.content }
        store.dispatch('app-user-vehicle-list/fetchVehicleEdit', data).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                table.value.snak = { open: true, msg: `错误码：${code}, ${msg}` }
                return
            }
            table.value.dialogEdit.open = false
            fetchVehicleList()
        }).catch(error => { table.value.snak = { open: true, msg: `网络错误：${error}` } }).finally(table.value.dialogEdit.loading = false)
    }

    watch([param], () => {
        fetchVehicleList()
    }, { deep: true })
    return {
        headers,
        param,
        table, enums,
        toEdit, doEdit,
        toDelRelation, delDriverVehicle,
        toAddRelation, addDriverVehicle,
        toBindCompany, bindCompany,
        fetchVehicleList,
        fetchDriverVehicleList,
    }
}
